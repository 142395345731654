var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('PageHeader',{attrs:{"breadcrumbLinks":_vm.breadcrumbLinks,"title":'Whatsapp',"subtitle":'Números'}}),_c('div',{staticClass:"page-content container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 left-content-column"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',{attrs:{"for":"wpp-name"}},[_vm._v("Nome de exibição do perfil do WhatsApp Business*")]),_c('input',{staticClass:"form-control",attrs:{"id":"wpp-name","placeholder":'Adicionar o nome',"maxlength":"512"}})]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"form-group mb-4"},[_c('label',{attrs:{"for":"wpp-footer"}},[_vm._v("Telefone")]),_c('tel-input',{ref:"phone_number",attrs:{"value":_vm.form.phone_number},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}})],1),_vm._m(4),_c('div',{staticClass:"btn-wrapper"},[_vm._m(5),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-outline-primary",staticStyle:{"margin-right":"10px"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('app.cancel')))]),_c('button',{staticClass:"btn btn-yup-purple ml-auto"},[_vm._v(" Salvar ")])])]),_vm._m(6)])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12 col-xl-12 info-header"},[_c('h4',[_vm._v("Números cadastrados")]),_c('p',[_vm._v(" Tenha todas as informações de seus números cadastrados na plataforma Meta Gerenciador do WhatsAPP. Cadastre um novo número de forma fácil, rápida e conveniente. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"info-alert"},[_c('div',[_c('span',{staticClass:"material-symbols-outlined info-icon"},[_vm._v("info")])]),_c('div',[_c('strong',[_vm._v("Solicitação de número:")]),_vm._v(" A solicitação para cadastro de novos números esta sujeita a um prazo de 7 dias úteis para efetivação. É possível verificar o status quando quiser acessando Ativos de negócios nas Configurações do Meta Business Suite e selecionando essa conta do WhatsApp. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group mb-4"},[_c('label',[_vm._v("Descrição da empresa: ")]),_c('textarea',{staticClass:"form-control",attrs:{"placeholder":"Crie uma breve descrição de sua empresa","rows":"5"}}),_c('small',{staticClass:"form-text text-muted"},[_vm._v("0/1024")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group mb-4"},[_c('label',{attrs:{"for":"wpp-footer"}},[_vm._v("Categoria")]),_c('select',{staticClass:"form-control",attrs:{"id":"wpp-footer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group mb-4"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"link-help"},[_c('span',{staticClass:"material-symbols-outlined info-icon"},[_vm._v("help")]),_c('a',{attrs:{"href":"https://developers.facebook.com/docs/whatsapp/cloud-api/get-started/migrate-existing-whatsapp-number-to-a-business-account"}},[_vm._v("Suporte")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group mb-4"},[_c('br'),_c('br')])
}]

export { render, staticRenderFns }